import React from 'react';
import cx from 'classnames';
import * as s from './Hero.module.scss';

export default function Hero ({
    smallTitle,
    children,
    rightSide,
    paddedHorizontal: paddedHorizontalArg,
}) {
    const paddedHorizontal = paddedHorizontalArg !== undefined
        ? paddedHorizontalArg
        : true;

    return (
        <div
            className={cx(
                s.hero,
                smallTitle && s.smallTitle,
                paddedHorizontal && s.paddedHorizontal,
            )}
        >
            {rightSide && (
                <div className={s.rightSide}>
                    {rightSide}
                </div>
            )}

            {children}

        </div>
    )
}