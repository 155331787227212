import React, { useLayoutEffect } from "react";
import { Helmet } from "react-helmet";
import heroImageCut from '../images/hero-websites-design-cut.png'
import crownFavicon from '../images/crown.png';

export default function Head({ children }) {
  useLayoutEffect(() => {
    let scripts = document.querySelectorAll('[data-inline-script="data-inline-script"]');
    scripts.forEach(function forEachScript(element) {
      const script = element.innerHTML;
      window.eval(script);
    });
  }, [])

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <link rel="icon" type="image/png" href={crownFavicon} sizes="32x32" />
      <meta property="og:image" content={heroImageCut} />
      <meta property="og:description" content="Créez votre site web à partir de 150 €. Pour votre entreprise, boutique en ligne, restaurateurs, agence immobilière, profession indépendante, tous les artistes, blogs et journaux, établissements éducatifs, et plus encore." />
      {children}
    </Helmet>
  )
}