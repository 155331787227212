import React, { useLayoutEffect, useState } from "react";
import { Link } from "gatsby";
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'


import logo from '../images/logo-websites-design-white.png'
import whatsapp from '../images/whatsapp.png'
import * as s from "./Nav.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Nav() {
    const [mobileMenuOpened, setMobileMenuOpened] = useState(false);

    useLayoutEffect(() => {
        document.getElementById('nav-phone-placeholder').innerHTML = atob('KzIxMiA2IDE2IDAyIDQ3IDk1');
        document.getElementById('nav-email-placeholder').innerHTML = atob("Y29udGFjdEB3ZWJzaXRlcy1kZXNpZ24ucHJv");
    }, [])

    function toggleMobileMenu() {
        console.log('called')
        setMobileMenuOpened(v => !v)
    }

    return (
        <>
            <div id={s.contactInfos}>
                <div id={s.wrapper}>
                    <img src={whatsapp} /> WhatsApp <span id="nav-phone-placeholder" />
                    <span>
                        <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: 8 }} />
                        <span id="nav-email-placeholder" />
                    </span>
                </div>
            </div>
            <nav id={s.nav}>
                <ul>
                    <li id={s.logo}>
                        <Link to="/">
                            <img src={logo} />
                        </Link>
                    </li>

                    <li className={s.desktop}><Link to="/notre-entreprise">Pourquoi nous ?<br/>Notre entreprise</Link></li>
                    <li className={s.desktop}><Link to="/realisations-et-demos">Réalisations & Démos</Link></li>
                    <li className={s.desktop}><Link to="/tarifs-et-devis">Tarifs & Devis</Link></li>
                    <li className={s.desktop}><Link to="/contact">Contact</Link></li>

                    <li className={s.mobile}>
                        <a href="javascript:;" onClick={toggleMobileMenu} style={{ textDecoration: 'none' }}>
                            Menu &nbsp;&nbsp;
                            <FontAwesomeIcon icon={mobileMenuOpened ? faXmark : faBars} width={28} size="2x" color="white" />
                        </a>
                    </li>
                </ul>

            </nav>
            <nav id={s.navMobile} style={{ display: mobileMenuOpened ? 'block' : 'none' }}>
                <li><Link to="/notre-entreprise">Pourquoi nous ? Notre entreprise</Link></li>
                <li><Link to="/realisations-et-demos">Réalisations & Démos</Link></li>
                <li><Link to="/tarifs-et-devis">Tarifs & Devis</Link></li>
                <li><Link to="/contact">Contact</Link></li>
            </nav>
        </>
    )
}

