import React, { useState } from 'react';
import { Link } from 'gatsby';
import { faLock } from '@fortawesome/free-solid-svg-icons';

import * as s from './Footer.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Footer() {
    return (
        <footer id={s.footer}>
            <div id={s.footerContainer}>
                <p>
                    Nous fabriquons des sites webs et des boutiques en ligne avec le logiciel WordPress et WooCommerce.
                </p>
                    
                <div id={s.footerSecure}>
                    <span id={s.footerLockIcon}>
                        <FontAwesomeIcon icon={faLock} size="lg" />
                    </span>
                    100% sécurisé. Paiement à la livraison du site. Satisfait ou annulé
                </div>
            </div>
        </footer>
    )
}